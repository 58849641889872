import React from "react"
import SchedulePage from "../components/page-types/SchedulePage"

const BurgfestspieleSchedule = () => (
  <SchedulePage
    content={{ venue: { pathname: "/burgfestspiele/schedule" } }}
  ></SchedulePage>
)

export default BurgfestspieleSchedule
